import Resource from '../../resource'
import request from '../../../utils/request'

class TransferOut extends Resource {
  constructor() {
    super('transfer')
  }

  list(query) {
    return request({
      url: `/transfer/out`,
      method: 'get',
      params: query
    })
  }

  request(payload) {
    return request({
      url: `/transfer/out/request`,
      method: 'post',
      data: payload
    })
  }

  delete(id) {
    return request({
      url: `/transfer/out/delete/${id}`,
      method: 'post'
    })
  }

  approve(id) {
    return request({
      url: `/transfer/out/approve/${id}`,
      method: 'post'
    })
  }
}

export { TransferOut as default }
