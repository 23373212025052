import Specialization from '../../../../api/student/students/specialization';

const specialization = new Specialization();

const specializationModule = {
    namespaced: true,
    state: {
        items: [],
        total: null,
        load: false,
        lookups: [],
        specializations: [],
    },
    getters: {
        items(state) {
            return state.items;
        },
        load(state) {
            return state.load;
        },
        total(state) {
            return state.total;
        },
        lookups(state) {
            return state.lookups;
        },
        specializations(state) {
            return state.specializations
        },
    },
    mutations: {
        SET_ITEMS: (state, items) => {
            state.items = items;
        },
        SET_TOTAL_ITEMS: (state, total) => {
            state.total = total;
        },
        SET_ITEMS_LOAD: (state, load) => {
            state.load = load;
        },
        SET_LOOKUPS: (state, lookups) => {
            if (lookups.terms)
                state.lookups.terms = Object.keys(lookups.terms).map(key => lookups.terms[key]);

            if (lookups.tags)
                state.lookups.tags = lookups.tags //Object.keys(lookups.tags).map(key => lookups.tags[key]);

            if (lookups.faculties)
                state.lookups.faculties = Object.keys(lookups.faculties).map(key => lookups.faculties[key]);

            if (lookups.bylaws)
                state.lookups.bylaws = Object.keys(lookups.bylaws).map(key => lookups.bylaws[key]);

            if (lookups.programs)
                state.lookups.programs = Object.keys(lookups.programs).map(key => lookups.programs[key]);

            if (lookups.levels)
                state.lookups.levels = Object.keys(lookups.levels).map(key => lookups.levels[key]);
        },
        SET_SPECIALIZATIONS(state, specializations) {
            state.specializations = specializations
        }
    },
    actions: {
        list({commit}, payload) {
            commit('SET_ITEMS_LOAD', true);
            return new Promise((resolve, reject) => {
                specialization.list(payload).then(response => {
                    commit('SET_ITEMS', response.data);
                    commit('SET_TOTAL_ITEMS', response.meta.count);
                    commit('SET_LOOKUPS', response.meta.lookup);
                    commit('SET_ITEMS_LOAD', false);
                    resolve(response);
                }).catch(error => {
                    commit('SET_ITEMS_LOAD', false);
                    reject(error);
                });
            });
        },

        bulkSpecialize({commit}, payload) {
            return new Promise((resolve, reject) => {
                specialization.bulkSpecialize(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        specializations({commit}, id) {
            return new Promise((resolve, reject) => {
                specialization.specializations(id)
                    .then(response => {
                        commit('SET_SPECIALIZATIONS', response.data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        deleteSpecialization({commit}, id) {
            return new Promise((resolve, reject) => {
                specialization.deleteSpecialization(id)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    },
};

export default specializationModule;
